@import '../config';

section {
	margin-bottom: $section-gutter;
	h1 {
		margin-bottom: 4rem;
		text-align: center;
	}
	
	@include max640px {
		margin-bottom: 60px;	
	}
}

.wrapper {
	max-width: $grid-max-width;
	width: 100%;
	margin: 0 auto;
	padding: 0 1rem;
	box-sizing: border-box;
}

table {
	border-collapse: collapse;
}