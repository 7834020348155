@import '../config';
@import '../core/breakpoints';

html,
body {
	font-size: $base-font-size;
	font-family: $font-roboto;
	line-height: $base-line-height;
	//font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: normal;
    font-style: normal;
}

body {
	overflow-x: hidden;
}

h1, h2, h3, h4, h5 {
	margin-bottom: 0.5em;
}

h1 {
	font-size: $h1-font-size;
	line-height: 1.25;
	color: #232C33;
	@include max640px {
		font-size: 2.5rem;
	}
}

h2 {
	font-size: $h2-font-size;
	line-height: 1.3;
	margin-bottom: 30px;
	@include max640px {
		margin-bottom: 20px;	
	}
}

h3 {
	font-size: $h3-font-size;
	line-height: 1.4;
	margin-top: 40px;
    margin-bottom: 20px;
	@include max940px {
		margin-top: 0;
	}
	
	@include max640px {
		margin-bottom: 15px;	
	}
}

h4 {
	font-size: $h4-font-size;
	margin-bottom: 20px;
	line-height: 1.05;
}

p {
	font-size: $base-paragraph-size;
	line-height: 1.73;
}

.perex {
	font-weight: 500;
}

ul {
	margin-bottom: 30px;
}

strong {
	font-weight: 600;
}

a {
	color: inherit;
	@if ($underlined-links == true) {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	} @else {
		text-decoration: none;
	}
}

small {
	font-weight: 300;
}

br {
	@include max640px {
		display: none;
	}
}
